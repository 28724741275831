@import '../../assets/styles/global.module.scss';
@import '../../assets/styles/include-media.module.scss';

.content {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 15px;

  .radioButtonOutside {
    height: 20px;
    width: 20px;
    align-items: center;
    justify-content: center;
    display: flex;

    border-radius: 10px;
    // border: 1px solid $primaryColor;
    transition: all 0.25s;
    cursor: pointer;

    &:hover {
      // border-color: lighten($primaryColor, 30);
    }

    &.disabled {
      cursor: default;
      &:hover {
        border-color: unset;
      }
    }

    .radioButtonInside {
      &.active {
        background-color: $primaryColor;
        height: 80%;
        width: 80%;
        border-radius: 20px;
      }

      &.disabled {
        background-color: #eee;
        height: 100%;
        width: 100%;
        border-radius: 20px;
      }
    }
  }

  span {
    font-family: 'Montserrat-Regular';
    align-items: center;
    font-size: 18px;
    margin-left: 15px;
  }
}
