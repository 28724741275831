@import '../../../assets/styles/global.module.scss';
@import '../../../assets/styles/include-media.module.scss';

.content {
  height: 100%;
  display: flex;
  width: 100%;

  margin-left: 0;
  margin-right: 0;

  .container {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-around;
    position: absolute;
    bottom: 0;
    display: -webkit-flex;
    top: 100px;
    height: calc(100% - 100px);

    @include media('<md') {
      top: 50px;
      height: calc(100% - 50px);
    }

    .headerContainer {
      display: flex;
      margin-top: 10px;
      font-size: 32px;

      display: -webkit-flex;

      @include media('>md') {
        margin-bottom: 20px;
      }

      @include media('retina2x') {
        font-size: 22px;
      }

      @include media('<md') {
        font-size: 16px;
        margin-top: 15px;
      }

      .title {
        font-family: 'Montserrat-Bold';

        span {
          display: block;
          text-align: center;
        }
      }

      .changeCurrencyContainer {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        display: -webkit-flex;

        @include media('<md') {
          margin-top: 5px;
        }

        div {
          position: relative;
          display: flex;
        }

        span {
          font-family: 'Montserrat-Regular';
          font-size: 25px;
          text-align: center;
          cursor: pointer;
          line-height: 39.5px;
          border-bottom: 1px solid #000;
          &:nth-child(2) {
            padding-left: 10px;
          }
          z-index: 1;

          @include media('<md') {
            font-size: 16px;
            line-height: 25px;
          }

          @include media('retina2x') {
            font-size: 20px;
            line-height: 30px;
          }
        }

        .bottomLine {
          height: 3px;
          position: absolute;
          width: 100%;
          background-color: #000;
          bottom: 0;
          left: 0;
          z-index: 5;
        }
      }
    }

    .productContainer {
      display: -webkit-flex;

      @include media('<md') {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        padding: 20px;
        display: -webkit-flex;

        &.productContainer::after {
          content: '';
          padding-right: 20px; /* smallest size that is cross browser */
        }
      }

      @include media('>=md') {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45vh;
      }

      .productBox {
        -webkit-flex: 0 0 auto;

        flex: 0 0 auto;
        background-color: #fcf4ed;
        padding: 20px;
        box-shadow: 0 0 20px 1px silver;
        text-decoration: none;
        border-radius: 5px;
        background-repeat: round;
        width: 45vh;
        height: 45vh;

        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-around;

        &:nth-child(2) {
          margin-left: 20px !important;
        }

        @include media('<md') {
          padding: 20px;
          height: 100%;
          width: 18rem;
          height: 18rem;
        }

        @include media('>=md', '<lg') {
          padding: 20px;
        }

        @include media('retina2x') {
          padding: 20px;
        }

        .title {
          font-family: 'Montserrat-Medium';
          font-weight: 600;
          margin-bottom: 8px;
          align-items: center;
          font-size: 25px;
          display: flex;

          @include media('<md') {
            font-size: 12px;
          }

          @include media('retina2x') {
            font-size: 12px;
          }

          img {
            height: 25px;
            margin-left: 10px;
          }
        }

        .subTitle {
          font-family: 'Montserrat-Regular';
          font-weight: 500;
          margin-bottom: 8px;

          @include media('<md') {
            font-size: 12px;
          }

          @include media('retina2x') {
            font-size: 12px;
          }
        }

        ul {
          list-style-type: unset;
          padding: 20px 30px;
          font-family: 'Montserrat-Regular';

          @include media('retina2x') {
            padding: 15px;
          }

          @include media('<sm') {
            padding: 10px 15px;
          }

          li {
            padding: 5px 0;
            font-size: 14px;

            @include media('<md') {
              font-size: 10px;
            }

            @include media('retina2x') {
              font-size: 10px;
            }
          }
        }

        .price {
          font-family: 'Montserrat-Bold';
          display: block;
          text-align: center;
          text-decoration: line-through;
          font-size: 35px;
          // position: absolute;
          // bottom: 0;
          justify-content: center;
          display: flex;
          width: 100%;
          left: 0;
          text-decoration-color: $primaryColor;

          @include media('<md') {
            font-size: 20px;
          }

          @include media('retina2x') {
            font-size: 20px;
          }
        }
      }
    }

    .priceInfoContainer {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;

      @include media('>=md') {
        flex: 1;
      }

      .mobilePreview {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: 'Montserrat-Regular' !important;
        justify-content: center;
        margin: 0 15px;

        img {
          height: 50px;
        }
      }

      span {
        @include media('<md') {
          font-size: 18px;
        }

        @include media('retina2x') {
          font-size: 18px;
        }

        font-size: 25px;
        font-family: 'Montserrat-Regular';
      }

      div:nth-child(2) {
        margin: 20px 0;
        position: relative;

        @include media('<md') {
          margin: 10px 0;
        }

        @include media('retina2x') {
          margin: 10px 0;
        }

        span {
          font-family: 'Montserrat-Bold';

          :nth-child(1) {
            font-size: 35px;
          }

          // @include media('<md') {
          //   font-size: 30px;
          // }
        }

        img {
          position: absolute;
          height: 100px;
          margin-left: 10px;
          top: -5px;
        }
      }

      span:nth-child(3) {
        font-family: 'Montserrat-Bold';
      }
    }

    .buttonContent {
      flex-direction: column;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .vatInfo {
    font-family: 'Montserrat-Bold';
    font-size: 10px;
  }

  .slideToTop {
    transition: all 1.2s;
    top: -1000px;
    opacity: 0 !important;
  }
}
