@import '../../assets/styles/include-media.module.scss';

$primaryColor: #ea9999;
$secondaryColor: #faebe0;
$backgroundColor: #fbf4ed;

@font-face {
  font-family: 'Montserrat-Regular';
  src: url('../fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('../fonts/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat-Medium';
  src: url('../fonts/Montserrat-Medium.ttf');
}

@font-face {
  font-family: 'Montserrat-Black';
  src: url('../fonts/Montserrat-Black.ttf');
}

@font-face {
  font-family: 'Montserrat-ExtraBold';
  src: url('../fonts/Montserrat-ExtraBold.ttf');
}

@font-face {
  font-family: 'DINCondensed-Light';
  src: url('../fonts/DINCondensed-Light.ttf');
}

$breakpoints: (
  xs: 576px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

input,
textarea,
button,
div,
select,
span,
a {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  -webkit-touch-callout: none;
}

span {
  outline: unset;
}

// MOVE TO THE SHARED STYLESHEETS

input {
  font-family: 'Montserrat-Medium';

  padding: 18px;

  border-radius: 36px;
  padding-left: 23px;
  width: 100%;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;

  outline: none;
  border: 1px solid #000;
  -webkit-user-select: text;
  background-color: transparent;
  transition: all 0.2s;

  &.error {
    transition: all 0.2s;

    border: 1px solid #ea9999;
    background-color: rgba(248, 221, 216, 0.7);

    @include placeholder {
      color: #ea9999;
    }
  }

  @include media('retina2x') {
    padding: 15px;
    margin: 2px 0;
    font-size: 12px;
  }

  @include media('<md') {
    padding: 18px;
    margin: 2px 0;
    font-size: 12px;
  }
}

.fadeIn {
  transition: all 1s;
  opacity: 1 !important;
}

.mobileTranslate {
  transform: translateY(0) !important;
  opacity: 1 !important;
  transition: all 1s ease-in;
}

.errorMessage {
  height: 15px;
  margin: 0;
  font-size: 8px;
  padding-left: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #ea9999;
  font-family: 'Montserrat-Medium';

  @include media('retina2x') {
    height: 6px;
  }

  @include media('<md') {
    height: 8px;
  }
}

// OH and ST styles
.backText {
  font-family: 'Montserrat-Light';
  font-size: 16px;
  color: #ea9999;
  opacity: 0.5;
  margin-top: 20px;
  text-align: center;
  cursor: pointer;

  @include media('retina2x') {
    margin-top: 10px;
    font-size: 14px;
  }

  @include media('<md') {
    margin: 15px 0;
    font-size: 14px;
  }
}

.buttonOHandSTContainer {
  @include media('<md') {
    margin-top: 25px;
  }
}
