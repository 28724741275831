@import '../../assets/styles/global.module.scss';
@import '../../assets/styles/include-media.module.scss';
@import '~rfs/scss';

.content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  width: 100%;

  .container {
    position: relative;
    height: 100%;
    width: 25%;
    margin-right: 10%;
    margin-top: 2.5vh;

    @include media('<md') {
      margin: 0;
      display: flex;
      margin-left: 0;
      width: 100%;
    }

    .headerContainer {
      margin-bottom: 10px;

      @include media('<md') {
        text-align: center;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
      }

      .title {
        font-family: 'Montserrat-Medium';
        font-weight: 600;
        margin-bottom: 8px;

        @include font-size(30px);

        text-align: right;

        @include media('retina2x') {
          font-size: 20px;
        }

        @include media('<md') {
          font-size: 1.4rem;
          line-height: unset;
          margin-bottom: 15px;
          text-align: center;
        }
      }

      .subTitle {
        font-family: 'Montserrat-Regular';
        font-size: 18px;
        font-weight: 500;
        text-align: right;

        @include media('<md') {
          font-size: 13px;
          text-align: center;
        }

        @include media('retina2x') {
          font-size: 12px;
        }
      }
    }

    .mainMobileContent {
      flex: 1;
      opacity: 0;
      transform: translateY(100%);
      overflow-x: hidden;
      background-color: #fff;

      .mobileBackgroundImage {
        height: 40vh;
        width: 100%;
        background-color: #fbf4ed;
        transform: scale(1.15);
      }

      .mainMobileContainer {
        flex: 1;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        top: 35vh;
        position: absolute;
        border-top-left-radius: 265px 75px;
        border-top-right-radius: 265px 75px;
        transform: translateY(0);

        transition: all 1s;

        .loginForm {
          margin: 10px 0;
        }

        .contentTranslate {
          transform: translateY(100%);
          transition: all 1s;
        }
      }
    }
  }

  .buttonContent {
    margin-top: 30px;

    justify-content: flex-end;
    display: flex;
    align-items: flex-end;

    @include media('<md') {
      align-items: center;
      display: flex;
      justify-content: center;
      margin-top: 10px;
      align-items: center;
    }
  }

  .bottomTextContent {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;

    @include media('retina2x') {
      margin-top: 15px;
    }

    @include media('<md') {
      align-items: center;

      margin-top: 0;

      width: 100%;
    }

    span {
      width: 200px;

      font-size: 13px;
      font-family: 'Montserrat-Regular';
      margin-bottom: 5px;
      font-weight: 200;
      text-align: center;

      @include media('retina2x') {
        font-size: 10px;
        width: 180px;
      }

      @include media('<md') {
        text-align: center;
        font-size: 13px;
      }

      &:nth-child(2) {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.loginPopUpContent {
  text-align: center;
  width: 100%;

  span {
    margin-bottom: 10px;
    display: inline-block;
  }
}
