.ReactModal__Overlay {
  /* //background-color: rgba(255, 255, 255, 0.5);
  transition: background-color 100ms ease-in-out;
  -webkit-perspective: 1000; */
}

.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.15);
}

.ReactModal__Overlay--before-close {
  background-color: rgba(0, 0, 0, 0);
}
