@import '../../assets/styles/global.module.scss';
@import '../../assets/styles/include-media.module.scss';
@import '~rfs/scss';

.content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-x: hidden;

  // MOVE TO THE SHARED STYLESHEETS

  .checkboxText {
    font-family: 'Montserrat-Regular';
    font-size: 12px;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;

    @include media('<md') {
      font-size: 10px;
    }
  }

  .dropdown {
    margin-top: 15px;
    font-family: 'Montserrat-Medium';
    padding: 18px;
    border-radius: 36px;
    padding-left: 23px;
    width: 100%;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    border: 1px solid #000;
    -webkit-user-select: text;
    background-color: transparent;
    transition: all 0.2s;

    &.error {
      transition: all 0.2s;
      border: 1px solid #ea9999;
      background-color: rgba(248, 221, 216, 0.7);
      ::placeholder {
        color: #ea9999;
      }
    }

    @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi) {
      padding: 15px;
      margin: 2px 0;
      font-size: 12px;
    }

    @media (max-width: 991px) {
      padding: 18px;
      margin: 2px 0;
      font-size: 12px;
    }
  }

  select option {
    background-color: #fff;
    color: #000;
    border-radius: 10px;
  }

  select option:checked {
    background-color: #000;
    color: #fff;
  }
  .select-items {
    position: absolute;
    background-color: DodgerBlue;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
  }

  .container {
    //.margin: 0 200px;
    position: relative;
    height: 100%;
    width: 35%;
    margin-left: 10%;
    margin-top: 2.5vh;

    @include media('<md') {
      background-color: #fff;

      margin: 0;
      display: flex;
      margin-left: 0;
      // position: absolute;
      width: 100%;
    }

    .headerContainer {
      margin-bottom: 30px;

      @include media('retina2x') {
        margin-bottom: 10px;
      }

      @include media('<md', 'retina2x') {
        text-align: center;
        margin-bottom: 0;
        margin-top: 50px;
      }

      .title {
        font-family: 'Montserrat-Medium';
        font-weight: 600;
        margin-bottom: 8px;
        @include font-size(30px);

        @include media('retina2x') {
          font-size: 20px;
        }

        @include media('<md') {
          font-size: 1.1rem;
          line-height: unset;
          margin-bottom: 10px;
        }
      }

      .subTitle {
        font-family: 'Montserrat-Regular';
        font-size: 18px;

        @include media('<md') {
          font-size: 10px;
        }

        @include media('retina2x') {
          font-size: 12px;
        }
      }
    }

    .imageResize {
      height: 30vh;
      transition: all 0.5s;
      transition-delay: 0.5s;
      transform: scale(1);
      width: 100%;
    }

    .mobileBackgroundImage {
      height: 40vh;
      width: 100%;
      background-color: #fbf4ed;
      margin-bottom: 30px;
      transition: all 0.5s;
      transition-delay: 0.5s;
      transform: scale(1.15);
    }

    .mobileContent {
      border-top-left-radius: 265px 75px;
      border-top-right-radius: 265px 75px;
      background-color: #fff;
      top: 35vh;
      position: absolute;
      overflow-x: hidden;

      &.contentResize {
        top: 15vh;
        transition: all 0.5s;
        transition-delay: 0.5s;
      }

      .mobileContainer {
        flex: 1;
        opacity: 0;
        height: 100%;
        transform: translateY(100%);
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
  }

  .buttonContent {
    @include media('<md') {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0 0 0 0;
      align-items: center;
    }
  }

  .termsContent,
  .redirectToLoginContent {
    padding: 10px;
    display: flex;
    flex-direction: column;

    .link {
      text-decoration: underline;
      cursor: pointer;
    }

    @include media('<md') {
      align-items: center;

      margin-top: 0;

      // flex: 0 0 0;

      // margin-bottom: 5px;
    }

    span {
      font-size: 10px;
      font-family: 'Montserrat-Regular';
      font-weight: 200;

      @include media('<md') {
        text-align: center;
        width: 40%;
        margin-bottom: 0;
      }
    }
  }

  .termsContent {
    span {
      @include media('<md') {
        margin-top: 5px;
      }
    }
  }

  .redirectToLoginContent {
    span {
      font-size: 13px;
      margin-bottom: 0;

      @include media('<md') {
        text-align: center;
        width: unset;
        margin-bottom: 0;
      }
    }
  }
}
.registrationPopUpContent {
  text-align: center;
  justify-content: center;
}

