@import '../../assets/styles/global.module.scss';
@import '../../assets/styles/include-media.module.scss';

.registrationPopUpContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;

  svg {
    fill: $primaryColor;
    height: 50px;
  }

  .map {
    width: 100%;
    height: 100%;
    margin-top: 15px;
  }

  span {
    font-size: 16px;
    text-align: center;
    font-family: 'Montserrat-Regular';

    @include media('<md') {
      font-size: 12px;
    }
  }

  span:nth-child(3) {
    @include media('<md') {
      font-size: 16px;
    }
    font-size: 22px;
    padding: 10px 0;
  }
}
