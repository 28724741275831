@import '../../assets/styles/global.module.scss';
@import '../../assets/styles/include-media.module.scss';

.title {
  span:nth-child(1) {
    font-size: 35px;
    font-family: 'Montserrat-Bold';
    margin-bottom: 15px;

    @include media('<md') {
      font-size: 20px;
    }
  }
}

.paragraph{
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: white;
  padding: 5%;
  span {
    font-size: 15px;
    font-family: 'Montserrat-Bold';
    align-self: center;

  }

  p {
    font-size: 15px;
    font-family: 'Montserrat-Regular';
  }
  ol {
    padding-left: 5%;
    list-style-type: disc;
    font-size: 15px;
    font-family: 'Montserrat-Regular';
  }
}
.content {
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #ffffff;

  span {
    display: block;
    text-align: center;
  }



  img {
    height: 45vh;

    @include media('<md') {
      height: 35vh;
    }
  }
  .container {
    font-family: 'Montserrat-Regular';
    font-size: 50px;

    span {
      display: block;
      text-align: center;
    }

    span:nth-child(1) {
      font-size: 50px;
      font-family: 'Montserrat-Bold';
      margin-bottom: 15px;

      @include media('<md') {
        font-size: 20px;
      }
    }

    span:nth-child(2) {
      font-size: 25px;

      @include media('<md') {
        font-size: 20px;
      }

      font-family: 'Montserrat-Regular';
    }

    .badgeContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
    }

    a {
      img {
        height: 60px;

        @include media('<md') {
          height: 50px;
        }
      }
    }

    a:nth-child(1) {
      img {
        margin-right: 20px;
      }
    }
  }
}
