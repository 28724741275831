@import '../../assets/styles/global.module.scss';
@import '../../assets/styles/include-media.module.scss';

.content {
  background-color: $backgroundColor;

  overflow: hidden;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  height: 100%;

  &.blur {
    filter: blur(5px);
    transition: all 0.3s;
  }

  &.nonBackgroundColor {
    background-color: #fff;
  }

  main {
    width: 100%;
    display: flex;

    overflow-y: auto;

    @include media('>md') {
      height: calc(100vh - 100px);
    }

    @include media('retina2x') {
      height: calc(100vh - 70px);
    }

    @include media('<md') {
      -webkit-justify-content: center;
      justify-content: center;

      height: 100vh;
    }
  }
}
