@import '../../assets/styles/global.module.scss';
@import '../../assets/styles/include-media.module.scss';

.content {
  height: 100%;
  display: flex;
  width: 95%;
  align-items: center;
  flex-direction: column;

  @include media('<md') {
    // justify-content: space-around;
  }

  .headerContainer {
    font-family: 'Montserrat-Medium';
    text-align: center;
    font-size: 16px;
    line-height: 22px;

    @include media('<md') {
      align-items: flex-end;
      justify-content: center;
      display: flex;
      font-size: 14px;
      width: 100%;
    }
  }

  .shopTypesContainer {
    font-family: 'Montserrat-Regular';
    // height: 85%;
    display: flex;
    // width: 85%;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-content: space-around;
    margin: 35px 0;
    flex: 1;

    @include media('retina2x') {
      margin: 15px 0;
    }

    @include media('<md') {
      margin: 15px 0 0 0;
    }

    .animationBackground {
      position: absolute;
      top: 0;
      left: 0;
      background: $secondaryColor;
      width: 100%;
      height: 100%;
      z-index: 5;
      border-radius: 36px;

      transition: 0.1s all;

      border: 2px solid #ea9999;
    }

    .shopType {
      position: relative;
      background-color: #fff;
      box-shadow: 0 0 3px 1px silver;
      font-size: 12px;
      margin: 10px;
      border-radius: 25px;
      cursor: pointer;
      display: flex;
      height: 30px;
      outline: none;

      @include media('retina2x') {
        margin: 6px;
      }

      @include media('<md') {
        height: 40px;
      }
    }

    .textContainer {
      z-index: 6;
      align-items: center;
      display: flex;
      height: 100%;
      width: 100%;
      padding: 10px;
    }
  }
}

.registrationPopUpContent {
  text-align: center;
}
