/* --- FADE IN --- */

.fade-in-enter {
  opacity: 0;
}
.fade-in-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.fade-in-exit {
  opacity: 1;
}
.fade-in-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.fade-in-enter-done {
  opacity: 1 !important;

  transition: all 0.5s ease-in;
}

/* --- mobile-navigation-translate --- */
.mobile-navigation-translate-enter {
  opacity: 0.01;
  transform: translateY(-100%);
}
.mobile-navigation-translate-enter-active {
  opacity: 1;
  transform: translateY(0%);
  transition: all 300ms ease-out;
}
.mobile-navigation-translate-exit {
  opacity: 1;
  transform: translateY(0%);
}
.mobile-navigation-translate-exit-active {
  opacity: 0.01;
  transform: translateY(-100%);
  transition: all 300ms ease-out;
}

/* --- mobile-navigation-item-translate --- */
.mobile-navigation-item-enter {
  max-height: 0;
  opacity: 0.01;
  overflow: hidden;
}
.mobile-navigation-item-enter-active {
  max-height: 150px;
  opacity: 1;
  transition: all 0.3s ease;
}
.mobile-navigation-item-exit {
  opacity: 1;
  max-height: 150px;
}
.mobile-navigation-item-exit-active {
  max-height: 0;
  transition: all 0.3s ease;
  overflow: hidden;
  opacity: 0.01;
}

/* --- button-animation --- */
.button-enter {
  transform: scale(0);
}
.button-enter-active {
  transform: scale(1);
  transition: all 0.5s ease;
}

.button-exit {
  transform: scale(1);
}

.button-exit-active {
  opacity: 0;
  transition: all 0.15s ease;
  z-index: 1;
}

/* --- mobile-registration-content-margin ---*/

.mobile-registration-content-margin-enter-active {
  transform: translateY(100%);
  opacity: 0 !important;

  transition: all 0.4s ease-in;
}

.mobile-registration-content-margin-enter-done {
  transform: translateY(0) !important;
  opacity: 1 !important;

  transition: all 0.4s ease-in;
}

.mobile-registration-content-margin-exit {
  transform: translateY(0) !important;
  opacity: 1 !important;
}

.mobile-registration-content-margin-exit-active {
  opacity: 0;

  transform: translateY(100%);
  transition: all 0.4s ease-out;
}

/* --- modal-scale-up ---*/

.modal-scale-up-enter {
  transform: scale(0.5) !important;
}
.modal-scale-up-enter-active {
  transform: scale(1) !important;
  transition: all 300ms ease-in;
}
.modal-scale-up-exit {
  transform: scale(1);
}
.modal-scale-up-exit-active {
  transform: scale(0.5);
  transition: all 300ms ease-out;
}

/* --- push-from-right ---*/
@keyframes bounce {
  0% {
    transform: translateX(500px);
  }
  60% {
    transform: translateX(-10px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes bounceReverse {
  0% {
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(500px);
  }
}

.shop-registration-side-pages-animation-enter-active {
  animation: bounce 500ms ease-in;
  transition: all 500ms ease-in;
}

.shop-registration-side-pages-animation-exit-active {
  animation: bounce 600ms ease-in forwards reverse;
  transition: animation 600ms ease-in;
}
