@import '../../../assets/styles/global.module.scss';
@import '../../../assets/styles/include-media.module.scss';
@import '~rfs/scss';

.content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @include media('<=md') {
    background-color: #fff;
    border-top-left-radius: 265px 75px;
    border-top-right-radius: 265px 75px;
  }

  .container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    div {
      flex: unset !important;
    }

    .firstContainer {
      width: 100%;
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    @include media('<md') {
      margin: 0;
      display: flex;
      margin-left: 0;
      width: 100%;
    }

    .headerContainer {
      margin-bottom: 30px;
      display: flex;
      flex-direction: column;

      @include media('<md', 'retina2x') {
        text-align: center;
        margin-bottom: 15px;
      }

      @include media('retina2x') {
        margin-bottom: 15px;
      }

      .title {
        font-family: 'Montserrat-Medium';
        font-weight: 600;
        margin-bottom: 15px;
        @include font-size(30px);

        @include media('retina2x') {
          font-size: 20px;
        }

        @include media('<md') {
          font-size: 5vw;
          line-height: unset;
          margin-bottom: 15px;
        }
      }

      .subTitle {
        font-family: 'Montserrat-Regular';
        font-size: 20px;

        @include media('<md') {
          font-size: 10px;
        }

        @include media('retina2x') {
          font-size: 12px;
        }
      }
    }

    .packageRow {
      display: flex;
      flex-direction: row;
      padding: 20px 0;
      justify-content: space-between;
      align-items: center;
      font-family: 'Montserrat-Medium';
      border-bottom: 1px solid #eee;

      .trachIcon {
        height: 20px;
        width: 20px;
        margin-right: 50px;
        cursor: pointer;

        @include media('<=md') {
          height: 15px;
          width: 15px;
          margin-right: 10px;
        }
      }

      img {
        height: 40px;
        width: 40px;
        margin-right: 15px;
        object-fit: contain;

        @include media('<=md') {
          height: 15px;
          width: 15px;
        }
      }

      .packageRowInfo {
        display: flex;
        flex-direction: row;
        width: 100%;

        align-items: center;

        justify-content: space-between;

        div {
          align-items: center;
          display: flex;
        }

        span {
          @include media('<=md') {
            font-size: 12px;
          }
        }

        .discountPrice,
        .price {
          font-family: 'Montserrat-Bold';
          font-size: 25px;

          @include media('<=md') {
            font-size: 12px;
          }
        }

        .price {
          text-decoration: line-through;
          margin-right: 15px;
          text-decoration-color: $primaryColor;
        }
      }
    }

    .totalPrice,
    .paymentTypeTitle {
      margin: 30px 0;
      font-size: 25px;
      text-align: right;
      font-family: 'Montserrat-Regular';

      @include media('<=md') {
        font-size: 20px;
        text-align: center;
      }
    }
    .vatInfo {
      margin: 30px 0;
      font-size: 15px;
      text-align: right;
      font-family: 'Montserrat-Regular';

      @include media('<=md') {
        font-size: 10px;
        text-align: center;
      }
    }
  }

  .paymentTypeContainer {
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    @include media('<=md') {
      flex-direction: column;
      align-items: unset;
    }

    .paymentTypeTitle {
      font-family: 'Montserrat-Bold';
      align-items: center;
    }

    .radioButtonContainer {
      display: flex;
      flex-direction: row;

      @include media('<=md') {
        flex-direction: column;
      }

      .radioButtonContentStyles {
        margin-right: 45px;
        @include media('<=md') {
          margin-top: 15px;
          margin-right: 0px;
        }
      }
    }
  }

  .buttonContent {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;

    .buttonContentStyles {
      width: 300px;
    }
  }
}

.checkoutPopup {
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: center;

  .popUpContent {
    display: flex;
    flex-direction: column;

    align-items: center;

    img {
      width: 155px;
      height: 59px;
      margin-bottom: 15px;
    }

    span {
      font-size: 15px;
      display: block;
      text-align: center;
      line-height: 20px;
      font-family: 'Montserrat-Regular';
    }

    .title {
      font-family: 'Montserrat-Bold';
    }
  }
}
