@import '../../assets/styles/global.module.scss';
@import '../../assets/styles/include-media.module.scss';

.overlayModal {
  z-index: 999;
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  .modal {
    background-color: #fff;
    transition: all 0.3s;

    display: flex;

    min-height: 240px;

    width: 30%;

    /*--- MOBILE PREVIEW ---*/

    @include media('<md') {
      width: 85%;
    }

    outline: none;
    border-radius: 25px;
    flex-direction: column;

    header {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 15px;
      span {
        font-size: 16px;
        text-align: center;
        font-family: 'Montserrat-Bold';

        @include media('<md') {
          font-size: 12px;
        }
      }
    }

    .content {
      display: flex;
      padding: 25px 25px 0 25px;
      flex: 1;
      font-family: 'Montserrat-Regular';
      align-items: center;
    }

    .footer {
      display: flex;
      align-items: center;
      padding: 15px;
      justify-content: center;
      background-color: #ffffff;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
      box-sizing: border-box;

      &.buttonsColumnPosition {
        flex-direction: column;

        .buttonContentStyles {
          margin-right: 0px;
          margin-bottom: 10px;
        }
      }

      .buttonContentStyles {
        margin-right: 10px;

        .secondaryButtonStyles {
          background-color: unset;
          color: $primaryColor;
          border: 1px solid $primaryColor;
          margin-right: 5px;
        }

        .animatedSecondaryButtonColor {
          background-color: rgba(234, 153, 153, 0.4) !important;
        }
      }
    }
  }
}

//--- ANIMATIONS ---//
.scaleUp {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.scaleUpReverse {
  -webkit-animation: scale-down-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-down-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fadeIn {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fadeInReverse {
  -webkit-animation: fade-in-reverse 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-reverse 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation scale-down-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
}
@keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation fade-in-reverse
 * ----------------------------------------
 */
@-webkit-keyframes fade-in-reverse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade-in-reverse {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
