@import '../../assets/styles/global.module.scss';
@import '../../assets/styles/include-media.module.scss';
@import '~rfs/scss';

.paymentContent {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-x: hidden;

  .container {
    //.margin: 0 200px;
    position: relative;
    height: 100%;
    width: 35%;
    margin-left: 10%;
    margin-top: 2.5vh;

    @include media('<md') {
      background-color: #fff;

      margin: 0;
      display: flex;
      margin-left: 0;
      // position: absolute;
      width: 100%;
    }

    .headerContainer {
      margin-bottom: 30px;

      @include media('retina2x') {
        margin-bottom: 10px;
      }

      @include media('<md', 'retina2x') {
        text-align: center;
        margin-bottom: 0;
        margin-top: 50px;
      }

      .title {
        font-family: 'Montserrat-Medium';
        font-weight: 600;
        margin-bottom: 8px;
        @include font-size(30px);

        @include media('retina2x') {
          font-size: 20px;
        }

        @include media('<md') {
          font-size: 1.1rem;
          line-height: unset;
          margin-bottom: 10px;
        }
      }

      .subTitle {
        font-family: 'Montserrat-Regular';
        font-size: 18px;

        @include media('<md') {
          font-size: 10px;
        }

        @include media('retina2x') {
          font-size: 12px;
        }
      }
    }

    .imageResize {
      height: 30vh;
      transition: all 0.5s;
      transition-delay: 0.5s;
      transform: scale(1);
      width: 100%;
    }

    .mobileBackgroundImage {
      height: 40vh;
      width: 100%;
      background-color: #fbf4ed;
      margin-bottom: 30px;
      transition: all 0.5s;
      transition-delay: 0.5s;
      transform: scale(1.15);
    }

    .mobileContent {
      border-top-left-radius: 265px 75px;
      border-top-right-radius: 265px 75px;
      background-color: #fff;
      top: 35vh;
      position: absolute;
      overflow-x: hidden;

      &.contentResize {
        top: 15vh;
        transition: all 0.5s;
        transition-delay: 0.5s;
      }

      .mobileContainer {
        flex: 1;
        opacity: 0;
        height: 100%;
        transform: translateY(100%);
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
  }

  .buttonContent {
    @include media('<md') {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0 0 0 0;
      align-items: center;
    }
  }

  .termsContent,
  .redirectToLoginContent {
    padding: 10px;
    display: flex;
    flex-direction: column;

    .link {
      text-decoration: underline;
      cursor: pointer;
    }

    @include media('<md') {
      align-items: center;

      margin-top: 0;

      // flex: 0 0 0;

      // margin-bottom: 5px;
    }

    span {
      font-size: 10px;
      font-family: 'Montserrat-Regular';
      font-weight: 200;

      @include media('<md') {
        text-align: center;
        width: 40%;
        margin-bottom: 0;
      }
    }
  }

  .termsContent {
    span {
      @include media('<md') {
        margin-top: 5px;
      }
    }
  }

  .redirectToLoginContent {
    span {
      font-size: 13px;
      margin-bottom: 0;

      @include media('<md') {
        text-align: center;
        width: unset;
        margin-bottom: 0;
      }
    }
  }
}
.registrationPopUpContent {
  text-align: center;
  justify-content: center;
}


.paymentElement {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #ffffff;
}

.input {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.paymentButton {
  background-color: #6200ea;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 20px 0px;
  cursor: pointer;
  border-radius: 4px;
  width: 30%;
  boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02)',
}

.button:disabled {
  background-color: #999;
}

.cardElement {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
}
