@import '../../assets/styles/global.module.scss';
@import '../../assets/styles/include-media.module.scss';

.buttonContent {
  position: relative;
  width: 200px;
  height: 55px;

  @include media('retina2x') {
    height: 45px;
    width: 180px;
  }

  @include media('<md') {
    height: 45px;
    width: 180px;
  }

  .button {
    width: 100%;
    height: 100%;
    outline: none;
    position: relative;
    font-family: 'Montserrat-Bold';
    cursor: pointer;
    border-radius: 36px;
    border: none;
    font-size: 15px;
    color: #fff;
    background-color: $primaryColor;

    img {
      height: 100%;
      width: 100%;
    }

    @include media('retina2x') {
      font-size: 13px;
    }

    @include media('<md') {
      font-size: 12px;
    }
  }

  .animationBackground {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
    z-index: 4;
    border-radius: 36px;
  }
}
