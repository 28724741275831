@import '../../../assets/styles/global.module.scss';
@import '../../../assets/styles/include-media.module.scss';

.headerContent {
  display: flex;
  display: -webkit-flex;
  height: 100px;
  position: sticky;
  top: 0;
  z-index: 99;

  @include media('retina2x') {
    height: 70px;
  }

  @include media('<md') {
    align-items: center;
    height: 50px;
  }

  .headerContainer {
    box-sizing: border-box;
    padding: 0 30px;
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @include media('retina2x') {
      height: 70px;
    }

    @include media('<md') {
      height: 50px;
    }

    .lokavorLogo {
      cursor: pointer;
      img {
        height: 60%;
      }

      @include media('retina2x') {
        align-items: center;
        display: flex;
        img {
          width: 105px;
          height: 39px;
        }
      }
    }

    .navContent {
      display: flex;

      .navContainer {
        position: relative;
        z-index: 3;
        padding-right: 50px;

        .mainNavText {
          font-family: 'Montserrat-Regular';
          font-size: 22px;
          color: #000;
          margin-left: 15px;
          line-height: 60px;
          cursor: default;
          z-index: 3;
          position: relative;
          cursor: pointer;
        }

        .navItemsContainer {
          position: absolute;
          display: flex;
          flex-direction: column;
          background-color: #f3f3f3;
          width: 100%;
          left: 0;
          top: 0;
          padding-top: 50px;
          z-index: 1;

          .navItem {
            font-family: 'Montserrat-Regular';
            font-size: 12px;
            margin: 20px;
            cursor: pointer;
            transition: 0.3s all;
            color: #000;
            text-decoration: none;

            &:hover {
              transition: 0.3s all;
              color: $primaryColor;
            }
          }
        }
      }
    }

    /*--- MOBILE PREVIEW ---*/

    @include media('<md') {
      margin: 0;
      padding: 0 15px;
      z-index: 5;
      background-color: #fbf4ed;
      position: fixed;
      width: 100%;
    }

    .mobileIcon {
      width: 87px;
      height: 35px;
    }

    .menuIcon {
      cursor: pointer;
      height: 25px;
    }
  }

  /*--- MOBILE PREVIEW ---*/

  .mobileMainNavContent {
    position: absolute;
    width: 100%;
    display: flex;
    top: 50px;
    flex-direction: column;
    z-index: 9999;

    .mobileMainNav {
      background-color: #f3f3f3;
      font-family: 'Montserrat-Regular';

      .mainNavText {
        padding: 0 10px;
        height: 40px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        border-bottom: 1px solid #eee;
      }
    }

    .navMobileItemsContent {
      .navItem {
        padding: 10px;
        display: flex;
        align-items: center;
        font-size: 13px;
        color: #000;
        text-decoration: none;
      }
    }
  }
}
