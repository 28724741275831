@import '../../assets/styles/global.module.scss';
@import '../../assets/styles/include-media.module.scss';

.buttonContent {
  margin: 15px 0 15px 0;

  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include media('<md') {
    margin: 0;

    align-items: center;
    display: flex;
    justify-content: center;
    padding: 10px;
    align-items: center;
    background-color: #fff;
  }

  span {
    width: 200px;
    font-size: 10px;
    font-family: 'Montserrat-Regular';
    margin-bottom: 10px;
    font-weight: 200;
    text-align: center;
  }
}

.buttonSelection {
  background-color: $secondaryColor;
  transition: 0.1s all;

  cursor: pointer;

  border: 0 solid #ea9999;

  @include placeholder {
    color: #ea9999;
  }

  &.active {
    transition: 0.1s all;

    border: 2px solid #ea9999;
  }
}

.bottomTextContent {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  @include media('<md') {
    align-items: center;

    margin-top: 0;

    flex: 0 0 0;
  }

  span {
    width: 200px;

    font-size: 13px;
    font-family: 'Montserrat-Regular';
    margin-bottom: 5px;
    font-weight: 200;
    text-align: center;

    @include media('<md') {
      text-align: center;
    }

    &:nth-child(2) {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.registrationPopUp {
  text-align: center;
  width: 100%;
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.customStylesModal {
  height: 70%;
  width: 40% !important;

  @include media('<md') {
    height: 80%;

    width: 90% !important;
  }
}
