@import '../../../assets/styles/global.module.scss';
@import '../../../assets/styles/include-media.module.scss';
@import '~rfs/scss';

.content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  // width: 100%;
  width: 100%;

  .container {
    position: relative;
    height: 100%;
    width: 50%;
    margin-top: 2.5vh;

    @include media('<md') {
      width: 100%;
      margin: 0;
      display: flex;
    }

    .registrationContainer {
      display: flex;
      flex-direction: row;

      .formContainer {
        width: 50%;
      }

      .sideComponentsContainer {
        width: 50%;
        height: 100%;
        display: flex;
      }

      @include media('<md') {
        margin: 0;
        display: flex;
        margin-left: 0;
        width: 100%;
      }

      .headerContainer {
        margin-bottom: 10px;

        .title {
          font-family: 'Montserrat-Medium';
          font-weight: 600;
          margin-bottom: 8px;

          @include font-size(30px);

          text-align: left;

          @include media('<md') {
            line-height: unset;
            margin-bottom: 5px;
            text-align: center;
            align-items: center;
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    .mainMobileContainer {
      flex: 1;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border-top-left-radius: 265px 75px;
      border-top-right-radius: 265px 75px;

      div {
        min-height: unset !important;
        flex: unset !important;
      }

      .headerContainer {
        .title {
          @include media('<md') {
            height: 50px;
            align-items: center;
            justify-content: center;
            display: flex;
            font-family: 'Montserrat-Medium';
          }
        }
      }
    }

    .customStylesModal {
      height: 70%;
      width: 40% !important;

      @include media('<md') {
        height: 80%;

        width: 90% !important;
      }
    }
  }
}

.mobilePopUpContent {
  height: 100%;
  width: 100% !important;
  overflow-y: auto;

  border-top-left-radius: 265px 75px !important;
  border-top-right-radius: 265px 75px !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;

  border-radius: 0;
}

.mobilePopUpOverlay {
  top: 50px;
  background-color: #fbf4ed;
}
