@import '../../../assets/styles/global.module.scss';

.content {
  position: absolute;

  height: 100%;
  width: 100%;

  margin-left: 0px;
  transition: all 0.5s;
  // transition-delay: 0.2s;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  display: flex;
  align-items: center;

  .container {
    width: 40%;
    margin-left: 20%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    &.registrationPage {
      margin-right: 20%;
      margin-left: 0;
      margin-top: 0;
    }

    &.subscription {
      margin-left: 10%;
    }

    img {
      height: 75vh;
    }
  }

  &.activeSideAnimation {
    margin-left: -35px;
    transition: all 0.5s;
    transition-delay: 0.1s;
  }
}
