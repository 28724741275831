@import '../../assets/styles/global.module.scss';
@import '../../assets/styles/include-media.module.scss';

.content {
  height: 100%;
  display: flex;
  width: 95%;
  align-items: center;
  flex-direction: column;

  @include media('<md') {
    width: 100%;

    // justify-content: space-around;
  }

  .headerContainer {
    font-family: 'Montserrat-Medium';
    text-align: center;
    font-size: 16px;
    line-height: 22px;

    @include media('<md') {
      margin-top: 20px;
      align-items: center;
      justify-content: center;
      display: flex;

      font-size: 14px;
      width: 80%;
    }
  }

  .openHoursContainer {
    font-family: 'Montserrat-Regular';
    display: flex;
    flex-direction: column;
    font-size: 15px;
    margin: 20px 0;
    width: 95%;
    flex: 1;

    @include media('<md') {
      width: 100%;
      margin: 0;
      align-items: center;
      justify-content: center;
    }

    .dayRow {
      flex-direction: row;
      display: flex;
      margin: 10px 0;
      justify-content: space-between;
      align-items: center;

      @include media('<md') {
        margin: 7px 0;
      }

      .workingHoursContainer,
      .breakHoursContainer {
        position: relative;
        box-shadow: 0 0 3px 1px silver;
        display: flex;
        justify-content: space-between;
        width: 55%;
        background-color: #fff;
        border-radius: 30px;
        align-items: center;
        padding: 5px;
        transition: all 0.5s;

        @include media('retina2x') {
          padding: 2px;
        }

        @include media('<md') {
          padding: 8px;
        }

        span {
          text-align: center;
          vertical-align: center;
          width: 50px;
        }

        .inputWidth {
          width: 100% !important;
        }

        input {
          font-size: 15px;
          width: 50% !important;
          height: 30px;
          border: unset;
          padding: 0;
          align-items: center;
          text-align: center;
          font-family: 'Montserrat-Regular';

          @include media('<md') {
            font-size: 13px;
          }
        }

        &.disabled {
          opacity: 0.5 !important;
          transition: all 0.5s;
        }

        div {
          width: 100%;
          display: flex;
          flex-direction: row;
          background-color: #f7e2d73d;
          align-items: center;
          border: 1px solid #f7e2d7;
          border-radius: 30px;
        }

        &.dayRowHeader {
          background-color: unset;
          box-shadow: unset;

          div {
            background-color: unset;
            border: unset;
            align-items: center;
            justify-content: center;
            display: flex;
          }

          input {
            font-size: 12px;
          }
        }
      }

      .breakHoursContainer {
        width: 35% !important;
      }
    }

    .closeIcon {
      color: $primaryColor;
      width: 5%;
      text-align: center;
      font-size: 15px;
      cursor: pointer;
    }
  }
}
